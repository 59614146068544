const selectContainer = document.getElementById('js_pn-select');
const countrySearchInput = document.getElementById('js_search-input');
const noResultListItem = document.getElementById('js_no-results-found');
const dropdownTrigger = document.getElementById('js_trigger-dropdown');
const phoneNumberInput = document.getElementById('js_input-phonenumber');
const dropdownContainer = document.getElementById('js_dropdown');
const selectedPrefix = document.getElementById('js_number-prefix');
const selectedFlag = document.getElementById('js_selected-flag');
const listContainer = document.getElementById('js_list');

let countryList;
let List;
const init = async (countries) => {
  const selectCountry = (e) => {
    const { flag, prefix } = e.target.closest('li').dataset;
    setNewSelected(prefix, flag);
    closeDropdown();
    addSelectedModifier(flag);
  };

  // -------------- Update the 'Selected country flag' to reflect changes

  const setNewSelected = (prefix, flag) => {
    selectedFlag.src = `https://flagpedia.net/data/flags/icon/36x27/${flag}.png`;
    selectedPrefix.value = `+${prefix}`;
    selectContainer.style.setProperty('--prefix-length', prefix.length);
  };

  // -------------- Removes and adds modifier to selected country

  const addSelectedModifier = (flag) => {
    const previousSelected = document.getElementsByClassName(
      'pn-list-item--selected'
    )[0];
    const newSelected = document.querySelectorAll(
      `.pn-list-item[data-flag=${flag}]`
    )[0];
    previousSelected.classList.remove('pn-list-item--selected');
    newSelected.classList.add('pn-list-item--selected');
  };

  // -------------- Close dropdown

  const closeDropdown = () => {
    selectContainer.classList.remove('pn-select--open');
    listContainer.scrollTop = 0;
    countrySearchInput.value = '';
    countryList.search();
    phoneNumberInput.focus();
    removeDropdownEvents();
  };

  // -------------- Open dropdown

  const openDropdown = () => {
    selectContainer.classList.add('pn-select--open');
    attatchDropdownEvents();
  };

  // -------------- Dropdown event listeners

  let countdown;

  const closeOnMouseLeave = () => {
    // console.log("countdown activated");
    countdown = setTimeout(() => closeDropdown(), 2000);
  };

  const clearTimeOut = () => clearTimeout(countdown);

  const attatchDropdownEvents = () => {
    // console.log("Adding event listeners");
    dropdownContainer.addEventListener('mouseleave', closeOnMouseLeave);
    dropdownContainer.addEventListener('mouseenter', clearTimeOut);
  };

  const removeDropdownEvents = () => {
    // console.log("Removing event listeners and countdown");
    clearTimeout(countdown);
    dropdownContainer.removeEventListener('mouseleave', closeOnMouseLeave);
    dropdownContainer.removeEventListener('mouseenter', clearTimeOut);
  };

  // -------------- Close when clicked outside the dropdown

  document.addEventListener('click', (e) => {
    if (
      e.target !== selectContainer &&
      !selectContainer.contains(e.target) &&
      selectContainer.classList.contains('pn-select--open')
    ) {
      closeDropdown();
    }
  });

  // -------------- Append generated listItems to list element

  const createList = () =>
    new Promise((resolve, _) => {
      countries.forEach((country, index, countries) => {
        const { name, prefix, flag } = country;

        const element = `<li class="pn-list-item ${
          flag === 'nl' ? 'pn-list-item--selected' : ''
        } js_pn-list-item" data-flag="${flag}" data-prefix="${prefix}" tabindex="0" role="button" aria-pressed="false">
          <img class="pn-list-item__flag" src="https://flagpedia.net/data/flags/icon/36x27/${flag}.png" />
          <span class="pn-list-item__country js_country-name">${name}</span>
          <span class="pn-list-item__prefix js_country-prefix">(+${prefix})</span>
        </li>`;

        listContainer.innerHTML += element;

        if (index === countries.length - 1) {
          resolve();
        }
      });
    });

  // -------------- After all the listItems are created we loop over the items to attach the eventListeners

  const attatchListItemEventListeners = () =>
    new Promise((resolve, _) => {
      const listItems = [...document.getElementsByClassName('js_pn-list-item')];

      listItems.forEach((item, index, listItems) => {
        item.addEventListener('click', (event) => {
          selectCountry(event);
        });
        // Keydown event listener - https://dev.to/tylerjdev/when-role-button-is-not-enough-dac
        item.addEventListener('keydown', function (e) {
          const keyD = e.key !== undefined ? e.key : e.keyCode;
          if (
            keyD === 'Enter' ||
            keyD === 13 ||
            ['Spacebar', ' '].indexOf(keyD) >= 0 ||
            keyD === 32
          ) {
            e.preventDefault();
            this.click();
          }
        });

        if (index === listItems.length - 1) {
          resolve();
        }
      });
    });

  // -------------- After all the listItems are created we initate list and it's listeners

  const initiateList = () => {
    countryList = new List('js_pn-select', {
      valueNames: ['js_country-name', 'js_country-prefix'],
    });
    console.warn('countryList', countryList);
    // Add 'updated' listener for search results
    countryList.on('updated', (list) => {
      if (list.matchingItems.length < 5)
        listContainer.classList.toggle('pn-list--no-scroll');

      noResultListItem.style.display =
        list.matchingItems.length > 0 ? 'none' : 'block';
    });
  };

  await createList();
  await attatchListItemEventListeners();
  initiateList();

  dropdownTrigger.addEventListener('click', () => {
    const isOpen = selectContainer.classList.contains('pn-select--open');
    isOpen ? closeDropdown() : openDropdown();
  });
};

const countries = [
  {
    name: 'Austria',
    prefix: 43,
    flag: 'at',
  },

  {
    name: 'Belgium',
    prefix: 32,
    flag: 'be',
  },

  {
    name: 'Bulgaria',
    prefix: 359,
    flag: 'bg',
  },

  {
    name: 'Croatia',
    prefix: 385,
    flag: 'hr',
  },

  {
    name: 'Cyprus',
    prefix: 357,
    flag: 'cy',
  },

  {
    name: 'Czech Republic',
    prefix: 420,
    flag: 'cz',
  },

  {
    name: 'Denmark',
    prefix: 45,
    flag: 'dk',
  },

  {
    name: 'Estonia',
    prefix: 372,
    flag: 'ee',
  },

  {
    name: 'Finland',
    prefix: 358,
    flag: 'fi',
  },

  {
    name: 'France',
    prefix: 33,
    flag: 'fr',
  },

  {
    name: 'Germany',
    prefix: 49,
    flag: 'de',
  },

  {
    name: 'Greece',
    prefix: 30,
    flag: 'gr',
  },

  {
    name: 'Hungary',
    prefix: 36,
    flag: 'hu',
  },

  {
    name: 'Iceland',
    prefix: 354,
    flag: 'is',
  },

  {
    name: 'Republic of Ireland',
    prefix: 353,
    flag: 'ie',
  },

  {
    name: 'Italy',
    prefix: 39,
    flag: 'it',
  },

  {
    name: 'Latvia',
    prefix: 371,
    flag: 'lv',
  },

  {
    name: 'Liechtenstein',
    prefix: 423,
    flag: 'li',
  },

  {
    name: 'Lithuania',
    prefix: 370,
    flag: 'lt',
  },

  {
    name: 'Luxembourg',
    prefix: 352,
    flag: 'lu',
  },

  {
    name: 'Malta',
    prefix: 356,
    flag: 'mt',
  },

  {
    name: 'Netherlands',
    prefix: 31,
    flag: 'nl',
  },

  {
    name: 'Norway',
    prefix: 47,
    flag: 'no',
  },

  {
    name: 'Poland',
    prefix: 48,
    flag: 'pl',
  },

  {
    name: 'Portugal',
    prefix: 351,
    flag: 'pt',
  },

  {
    name: 'Romania',
    prefix: 40,
    flag: 'ro',
  },

  {
    name: 'Slovakia',
    prefix: 421,
    flag: 'sk',
  },

  {
    name: 'Slovenia',
    prefix: 386,
    flag: 'si',
  },

  {
    name: 'Spain',
    prefix: 34,
    flag: 'es',
  },

  {
    name: 'Sweden',
    prefix: 46,
    flag: 'se',
  },
];

// export init(countries);
// export
export { countries, init };
